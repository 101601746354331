import React from "react";

export const InformativaDatiTitle =
  "Informativa Trattamento Dei Dati Personali";
export const TOSTitle = "Condizioni Generali di Licenza d'uso UCCS";

export const TOS = () => (
  <div>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mi quis ex mattis blandit non et nunc. In hac habitasse platea dictumst. Integer egestas lacinia justo, sit amet dignissim turpis vulputate non. In ex sapien, tempor id fermentum ut, efficitur non erat. Nunc et ligula sollicitudin, mattis diam eget, laoreet velit. Duis dui lacus, tempor at purus in, scelerisque maximus est. Proin convallis ipsum et tellus tristique, sit amet suscipit odio volutpat. Integer auctor felis vitae mi fermentum, sit amet luctus elit cursus. Vivamus vehicula, libero pretium ullamcorper commodo, felis nulla rutrum dolor, ac malesuada enim dolor in lorem. Nunc pharetra ipsum in risus hendrerit, quis pulvinar elit pharetra. Phasellus dapibus commodo maximus. Nam sed nibh dapibus, porta est vel, lacinia diam. Integer et mauris sed ligula interdum vestibulum.

    Proin sed diam ex. Aenean faucibus dui consectetur turpis consequat varius. Phasellus pulvinar velit eu mattis fringilla. Donec euismod, nunc eu luctus maximus, lectus lacus varius urna, a mattis nisl quam vel metus. Ut rutrum nulla a justo hendrerit cursus. Morbi et ante vitae tellus luctus accumsan et vel elit. Proin eget elit dolor. Aenean ultrices lacus et vestibulum lacinia. Nulla vel euismod dolor. Nullam vitae ante vel tortor faucibus rhoncus vitae id odio. Quisque pulvinar mauris non lorem eleifend pellentesque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras varius dui vel ultrices bibendum. Cras libero tortor, dapibus id ipsum vitae, sagittis interdum lacus.

    Nulla pharetra lobortis risus sed dignissim. Nulla sit amet gravida arcu, ac semper velit. Sed viverra, est vitae blandit finibus, diam lectus eleifend ligula, sed aliquam risus nisl ut lorem. Praesent aliquet mollis metus nec imperdiet. Pellentesque quis venenatis libero. Vestibulum condimentum purus vel eros congue porta. Integer a varius lorem, vel ultrices orci. Sed nec est magna. Sed finibus justo at arcu tincidunt, eget iaculis magna egestas.

    Pellentesque sit amet leo ut elit consequat vehicula. Sed efficitur urna ultrices imperdiet convallis. In hendrerit hendrerit arcu id luctus. Curabitur euismod luctus tortor. Sed commodo malesuada mauris, vel iaculis sapien sodales ut. Nulla facilisi. Suspendisse placerat eros magna, a sollicitudin lectus cursus et. Maecenas vehicula tempus eros et rhoncus. Quisque urna neque, dictum ut quam nec, tempus dapibus velit. Donec arcu nisi, placerat nec libero sed, consectetur facilisis dui. Fusce fringilla vulputate risus eu elementum. Maecenas commodo magna vel ligula consequat imperdiet. Nam tempor est at nulla euismod sollicitudin. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.

    Mauris justo orci, hendrerit a sapien in, aliquet vehicula felis. Duis iaculis sem ut magna volutpat suscipit. Etiam a justo ac metus vehicula luctus nec sit amet enim. Phasellus molestie semper congue. Suspendisse fringilla accumsan egestas. Ut et condimentum lacus. Curabitur consectetur turpis vitae leo iaculis, et fringilla erat sollicitudin. Cras ut metus eu dolor mattis tristique.
  </div>
);

export const InformativaDati = () => (
  <div>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mi quis ex mattis blandit non et nunc. In hac habitasse platea dictumst. Integer egestas lacinia justo, sit amet dignissim turpis vulputate non. In ex sapien, tempor id fermentum ut, efficitur non erat. Nunc et ligula sollicitudin, mattis diam eget, laoreet velit. Duis dui lacus, tempor at purus in, scelerisque maximus est. Proin convallis ipsum et tellus tristique, sit amet suscipit odio volutpat. Integer auctor felis vitae mi fermentum, sit amet luctus elit cursus. Vivamus vehicula, libero pretium ullamcorper commodo, felis nulla rutrum dolor, ac malesuada enim dolor in lorem. Nunc pharetra ipsum in risus hendrerit, quis pulvinar elit pharetra. Phasellus dapibus commodo maximus. Nam sed nibh dapibus, porta est vel, lacinia diam. Integer et mauris sed ligula interdum vestibulum.

    Proin sed diam ex. Aenean faucibus dui consectetur turpis consequat varius. Phasellus pulvinar velit eu mattis fringilla. Donec euismod, nunc eu luctus maximus, lectus lacus varius urna, a mattis nisl quam vel metus. Ut rutrum nulla a justo hendrerit cursus. Morbi et ante vitae tellus luctus accumsan et vel elit. Proin eget elit dolor. Aenean ultrices lacus et vestibulum lacinia. Nulla vel euismod dolor. Nullam vitae ante vel tortor faucibus rhoncus vitae id odio. Quisque pulvinar mauris non lorem eleifend pellentesque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras varius dui vel ultrices bibendum. Cras libero tortor, dapibus id ipsum vitae, sagittis interdum lacus.

    Nulla pharetra lobortis risus sed dignissim. Nulla sit amet gravida arcu, ac semper velit. Sed viverra, est vitae blandit finibus, diam lectus eleifend ligula, sed aliquam risus nisl ut lorem. Praesent aliquet mollis metus nec imperdiet. Pellentesque quis venenatis libero. Vestibulum condimentum purus vel eros congue porta. Integer a varius lorem, vel ultrices orci. Sed nec est magna. Sed finibus justo at arcu tincidunt, eget iaculis magna egestas.

    Pellentesque sit amet leo ut elit consequat vehicula. Sed efficitur urna ultrices imperdiet convallis. In hendrerit hendrerit arcu id luctus. Curabitur euismod luctus tortor. Sed commodo malesuada mauris, vel iaculis sapien sodales ut. Nulla facilisi. Suspendisse placerat eros magna, a sollicitudin lectus cursus et. Maecenas vehicula tempus eros et rhoncus. Quisque urna neque, dictum ut quam nec, tempus dapibus velit. Donec arcu nisi, placerat nec libero sed, consectetur facilisis dui. Fusce fringilla vulputate risus eu elementum. Maecenas commodo magna vel ligula consequat imperdiet. Nam tempor est at nulla euismod sollicitudin. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.

    Mauris justo orci, hendrerit a sapien in, aliquet vehicula felis. Duis iaculis sem ut magna volutpat suscipit. Etiam a justo ac metus vehicula luctus nec sit amet enim. Phasellus molestie semper congue. Suspendisse fringilla accumsan egestas. Ut et condimentum lacus. Curabitur consectetur turpis vitae leo iaculis, et fringilla erat sollicitudin. Cras ut metus eu dolor mattis tristique.
  </div>
);
